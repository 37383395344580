import React, { useEffect, useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import { firebaseFunc } from "./firebase/firestore";

const NewsletterSignUpFormVertical = ({ currentUser, firstName }) => {
  const [show, setShow] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    // phone verification
    const phoneParsed = parsePhoneNumberFromString(form.phone.value);
    if (!(phoneParsed && phoneParsed.isValid())) {
      alert(
        "Phone number is invalid. Please make sure you follow the required format: +18001234567",
      );
      return;
    }

    addToMailchimp(form.email.value, {
      FNAME: form.fname.value,
      PHONE: form.phone.value,
    })
      .then((data) => {
        if (data.result === "error") {
          setErrorMsg(
            `Error subscribing. ${data.msg.replace(
              /href/g,
              'target="_blank" href',
            )}`,
          );
        } else {
          setErrorMsg("");
          setShow(false);
          window.localStorage.setItem("newsletter_subscribed", "true");
          alert("Thank you!");
        }
      })
      .catch(() => {
        alert(
          "Error subscribing. Your browser may be blocking some resources. Please disable adblock.",
        );
      });
  };

  return (
    <>
      {show && (
        <form onSubmit={handleSubmit}>
          <div className="is-flex  newsletter-div-main m-b-lg">
            <div
              className="is-flex newsletter-div-sub p-l-md p-r-md"
              style={{
                // flexDirection: "column",
                height: "auto",
                width: "fit-content",
                borderRadius: "6px",
              }}
            >
              <div className="newsletter-div-label">
                {/* <i
                  className="fas fa-envelope-open-text has-text-grey-lighter is-size-3 m-r-md"
                  style={{
                    paddingTop: "0.2rem",
                  }}
                /> */}
                <div>
                  {/* <p className="label has-text-light m-b-sm m-t-md">
                    REQUEST AN INVITE!!
                  </p> */}
                  {/* <p className="label has-text-grey-lighter m-b-none is-size-7">
                    Subscribe and get timely updates and our free networking
                    ebook!
                  </p> */}
                </div>
              </div>
              <div className="field m-b-sm m-t-sm m-r-sm newsletter-field glued-field">
                <div className="control">
                  <input
                    id="newsletter_form_name"
                    type="text"
                    placeholder="First Name"
                    className="input"
                    name="fname"
                    autoComplete="given-name"
                    required
                    onChange={() => {
                      setErrorMsg("");
                    }}
                    style={
                      {
                        // borderColor: "#ffffff",
                        // boxShadow: "none",
                      }
                    }
                  />
                </div>
              </div>
              <div className="field m-b-sm m-t-sm m-r-sm newsletter-field glued-field">
                <div className="control">
                  <input
                    id="newsletter_form_email"
                    type="email"
                    placeholder="Email"
                    className="input"
                    name="email"
                    autoComplete="email"
                    required
                    onChange={() => {
                      setErrorMsg("");
                    }}
                    style={
                      {
                        // borderColor: "#ffffff",
                        // boxShadow: "none",
                      }
                    }
                  />
                </div>
              </div>
              <div className="field m-b-sm m-t-sm m-r-sm newsletter-field glued-field">
                <div className="control">
                  <input
                    id="newsletter_form_phone"
                    type="tel"
                    placeholder="Mobile Number"
                    className="input"
                    name="phone"
                    autoComplete="tel"
                    required
                    onChange={() => {
                      setErrorMsg("");
                    }}
                    style={
                      {
                        // borderColor: "#ffffff",
                        // boxShadow: "none",
                      }
                    }
                  />
                </div>
              </div>
              <button
                id="save-profile-btn"
                type="submit"
                className="button is-black m-b-sm m-t-sm glued-btn"
              >
                Request an Invite
              </button>
            </div>
            {errorMsg && (
              <p
                className="has-text-warning has-text-centered m-b-md yellow-link"
                id="newsletter-error-msg"
                dangerouslySetInnerHTML={{ __html: errorMsg }}
              />
            )}
          </div>
        </form>
      )}
    </>
  );
};

export { NewsletterSignUpFormVertical };
