import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import logo from "../img/logo.png";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { isUnderConstructionMode } from "../utils/utils";
import { NewsletterSignUpFormVertical } from "../components/NewslletterSignUpFormVertical";

export const UnderConstructionTemplate = ({
  title,
  content,
  contentComponent,
  location,
}) => {
  const PageContent = contentComponent || Content;

  useEffect(() => {
    if (!isUnderConstructionMode()) {
      navigate("/");
    }
  }, []);

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1 has-text-centered">
            <img
              className="m-b-lg logo-glued"
              src={logo}
              alt="OCCASIO"
              style={{}}
            />
            <div className="glued-vertical-center">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light has-text-centered">
                {title}
              </h2>
              <PageContent
                className="content has-text-centered"
                content={content}
              />
              <NewsletterSignUpFormVertical currentUser="" firstName="" />
              {/* <div className="column flex-column-centered">
                <span className="flex-row-centered ">
                  <a
                    className="fab fa-facebook-f is-size-3 m-b-lg has-text-grey-dark"
                    style={{
                      fontSize: "4rem",
                    }}
                    target="_blank"
                    href="https://www.facebook.com/onOCCASIO/"
                  ></a>
                  <a
                    className="fab fa-twitter is-size-3 m-b-lg m-l-lg has-text-grey-dark"
                    style={{
                      fontSize: "4rem",
                    }}
                    target="_blank"
                    href="https://twitter.com/onoccasio"
                  ></a>
                  <a
                    className="fab fa-instagram is-size-3 m-b-lg m-l-lg has-text-grey-dark"
                    style={{
                      fontSize: "4rem",
                    }}
                    target="_blank"
                    href="https://www.instagram.com/onoccasio"
                  ></a>
                  <a
                    className="fab fa-linkedin-in is-size-3 m-b-lg m-l-lg has-text-grey-dark"
                    style={{
                      fontSize: "4rem",
                    }}
                    target="_blank"
                    href="https://www.linkedin.com/company/15820548/"
                  ></a>
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

UnderConstructionTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  location: PropTypes.object,
  contentComponent: PropTypes.func,
};

const UnderConstruction = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    // <Layout>
    <UnderConstructionTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
      location={location}
    />
    // </Layout>
  );
};

UnderConstruction.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default UnderConstruction;

export const underConstructionQuery = graphql`
  query UnderConstruction($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
